/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useAuthenticationService } from "admin-portal-shared-services";
import {
  COMPANY_TOGGLE_PROD,
  COMPANY_TOGGLE_UAT,
  getVendorName,
  UserConfig,
  useUserConfigService,
} from "grow-shared-services";
import Moment from "moment";
import { v4 as guid } from "uuid";

import AgentCall from "@domains/AgentCall";
import EarnRule from "../../domains/EarnRule";
import { MarketplaceItem } from "../../domains/Marketplace";
import { CLASSIFICATION_VALUES } from "../../domains/Mission";
import { PaymentMethodInfoItem } from "../../domains/paymentMethodInfo/PaymentMethodInfo";
import PocInformation from "../../domains/PocInformation";
import GAP_REASONS from "../../routes/call/components/header/gapReason/gapReasons";
import {
  AZURE_B2C_REDIRECT_URI_PROD_B2C,
  AZURE_B2C_REDIRECT_URI_PROD_EUROPE_B2C,
  B2O_PREFIX,
  CURRENTDATE_IN_MILLIS,
  LOCAL_STORAGE_PORTAL_CONFIG,
  MISSION_TABS,
  MISSION_TAGS,
  PACKAGE_TYPE,
  PROMOTIONS_TYPES,
  RESPONSEDATE_IN_MILLIS,
  UPDATE_LOCAL_STORAGE_LOGOUT,
} from "../constants";
import { B2C_URL } from "../urls";

const returnStringWithGapDescription = (gapDesc: string) => {
  if (gapDesc) {
    return `${gapDesc}`;
  }
  return "";
};

export const getPhoneSanitized = (phone) => phone.replace(/[^\w\s]/gi, "");

export const concatenateGapDescription = (
  translate: (value: string) => string,
  gapDesc: string,
  gapReason: string,
  hasTechProblem: boolean,
) => {
  if (hasTechProblem || gapReason === GAP_REASONS[4].value) {
    return `${translate(
      "GapReason.HAS_TECHNICAL_PROBLEM",
    )} \n${returnStringWithGapDescription(gapDesc)}`;
  }
  return gapDesc || "";
};

export const getAddress = (
  pocInformation: PocInformation,
): string | undefined => {
  let addressText = "";

  if (pocInformation.address) {
    addressText = `${pocInformation.address}`;
  }
  if (pocInformation.city) {
    addressText = `${addressText} ${pocInformation.city}`;
  }
  if (pocInformation.state) {
    addressText = `${addressText} ${pocInformation.state}`;
  }
  if (pocInformation.zipcode) {
    addressText = `${addressText} ${pocInformation.zipcode}`;
  }

  return addressText || undefined;
};

export const noop = (): void => {
  // This functions do nothing, is just to we use when have default value as a empty function and we dont wanna cover it with tests
};

export const extractNumber = (text?: string) => {
  if (text) {
    return Number(text.replace(/\D/g, ""));
  }
  return null;
};

export const formarterCTIWithZero = (text?: string) => {
  if (text) {
    return text.replace(/\D/g, "");
  }
  return null;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueOrNull = (value: any): any => value || null;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */

export const getBooleanOrFalse = (value: boolean | undefined): boolean =>
  !!value || false;

export const getValueEmpty = (value: string | undefined): string => value ?? "";

export const updateLocalStorage = (key: string, value?: string): void => {
  localStorage.setItem(key, value ?? "");
};

export const removeItemsLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const updateListLocalStorage = (keys: string[]): void => {
  if (keys.length > 0) keys.forEach((key) => updateLocalStorage(key));
};

export const removeListLocalStorage = (keys: string[]): void => {
  if (keys.length > 0) keys.forEach((key) => removeItemsLocalStorage(key));
};

const AUTH_HEADER_KEY = "authHeader";
export const getTokenLocalStorage = (): string => {
  const token = useAuthenticationService().getAuthHeader();

  if (!token || isEmpty(token)) {
    const tokenAux = localStorage.getItem(AUTH_HEADER_KEY);

    if (tokenAux) {
      useAuthenticationService().setAuthHeader(tokenAux);
      return tokenAux;
    }

    const endPointLogout = urlLogout();
    removeListLocalStorage(UPDATE_LOCAL_STORAGE_LOGOUT);
    window.location.href = endPointLogout;
    throw new Error("Invalid getTokenLocalStorage token!");
  }

  return token;
};

export const isB2CLogin = (): boolean => {
  const portalLocalStorage = localStorage.getItem("portal-config");

  if (portalLocalStorage) {
    const portalConfig = JSON.parse(portalLocalStorage);

    if (portalConfig.ENV === "prod") {
      return window.location.origin.includes(B2C_URL);
    }
  }

  return true;
};

export const calculatePointsRewards = (rules: Array<EarnRule>): number => {
  let totalPoints = 0;

  for (const { points } of rules) {
    totalPoints += points;
  }

  return Math.round(totalPoints);
};

export const getCanaryRelease = (): string | null => {
  return localStorage.getItem("version");
};

export const isCanary = (): boolean => {
  return localStorage.getItem("version") === "canary";
};

export const getEnvironment = (): string => {
  const portalLocalStorage = localStorage.getItem("portal-config") ?? "{}";
  const portalConfig = JSON.parse(portalLocalStorage);
  switch (portalConfig.ENV) {
    case "qa":
      return "SIT";
    case "uat":
      return "UAT";
    case "prod":
      return "PROD";
    case "localhost":
      return "localhost";
    default:
      return "DEV";
  }
};

export const getB2OTag = (tag?: string): string => {
  if (!tag) return "";
  return `${B2O_PREFIX}${tag.substring(1)}`;
};

export const getCountryByToken = (): string => {
  const supportedCountries = useAuthenticationService().getSupportedCountries();

  if (supportedCountries && supportedCountries.length > 0) {
    return supportedCountries[0];
  }

  return useAuthenticationService().getCountryB2C();
};

export const getUserOrganizationId = (): string => {
  const {
    data: { orgId },
  } = useUserConfigService().getStoreValue();

  return orgId;
};

export const getUserInfos = (): UserConfig => {
  const userConfigService = useUserConfigService();

  if (
    !userConfigService ||
    typeof userConfigService.getStoreValue !== "function"
  ) {
    return {
      country: "",
      userEmail: "",
      toggle: "",
      preferredLanguage: "",
      groupId: "",
      orgId: "",
      vendors: [],
      missionPriority: [],
      targetKpi: [],
      vendorId: "",
    };
  }

  const { data } = userConfigService.getStoreValue();

  return { ...data };
};

export const setPocVendorId = (vendorId: string): void => {
  useUserConfigService().setPocVendorId(vendorId);
};

export const clearPocVendorId = (): void => {
  useUserConfigService().clearPocVendorId();
};

export const getRequestTraceId = (): string => {
  return guid();
};

export const getResponseDate = (): Moment.Moment => {
  const currentDateInMillis = localStorage.getItem(CURRENTDATE_IN_MILLIS);
  const responseDateInMillis = localStorage.getItem(RESPONSEDATE_IN_MILLIS);

  if (
    !currentDateInMillis ||
    !responseDateInMillis ||
    // eslint-disable-next-line no-restricted-globals
    isNaN(<never>currentDateInMillis) ||
    // eslint-disable-next-line no-restricted-globals
    isNaN(<never>responseDateInMillis)
  )
    return Moment.utc();

  const currentMillis = Number(Moment.utc().format("x"));
  const millis = Number(currentDateInMillis);
  const interval = currentMillis - millis;
  const responseDate = Moment(responseDateInMillis, "x").utc();
  return responseDate.add(interval, "milliseconds");
};

export const getDatesRange = (
  startDate: string,
  endDate: string,
  type: Moment.unitOfTime.Diff,
): Array<Moment.Moment> => {
  const fromDate = Moment(startDate);
  const toDate = Moment(endDate);
  const diff = toDate.diff(fromDate, type);
  const range: Array<Moment.Moment> = [];
  for (let i = 0; i <= diff; i += 1) {
    range.push(Moment(startDate).add(i, type));
  }
  return range;
};

export const urlLogout = (): string => {
  return window.origin;
};

export const urlEuropeLogout = (ENV: string): string => {
  const urlOrigin = window.origin;

  if (
    ENV === "uat" ||
    urlOrigin.indexOf("beesgrow-uat.bees-platform.dev/") > -1
  ) {
    return createEuropeUrlLogout("uat1", "uat");
  }

  return `https://b2biamgbusprod.b2clogin.com/b2biamgbusprod.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_BEES_SIGNIN_ONCUSTOMER&redirect_uri=${AZURE_B2C_REDIRECT_URI_PROD_EUROPE_B2C}`;
};

function createEuropeUrlLogout(prefix: string, sufix: string): string {
  return `https://b2biamgbus${prefix}.b2clogin.com/b2biamgbus${prefix}.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_BEES_SIGNIN_ONCUSTOMER&redirect_uri=https://beesgrow-${sufix}.bees-platform.dev/tlp/home`;
}

export const urlGlobalLogout = (ENV: string): string => {
  const urlOrigin = window.origin;

  if (
    process.env.NODE_ENV === "development" ||
    ENV === "dev" ||
    urlOrigin.indexOf("cx-tlp-dev") > -1
  ) {
    return createGlobalUrlLogout("dev", "dev");
  }

  if (ENV === "uat" || urlOrigin.indexOf("cx-tlp-uat") > -1) {
    return createGlobalUrlLogout("uat1", "uat");
  }

  return `https://b2biamgbusprod.b2clogin.com/b2biamgbusprod.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_BEES_SIGNIN_ONCUSTOMER&redirect_uri=${AZURE_B2C_REDIRECT_URI_PROD_B2C}`;
};

function createGlobalUrlLogout(prefix: string, sufix: string): string {
  return `https://b2biamgbus${prefix}.b2clogin.com/b2biamgbus${prefix}.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_BEES_SIGNIN_ONCUSTOMER&redirect_uri=https://cx-tlp-${sufix}.bees-platform.dev/tlp/home`;
}

export const getTabs = (
  tags: string[],
  isFeatureToggleTabsEnabled: {
    sponsored: boolean;
    service: boolean;
    wipRewardsRedemption: boolean;
  },
): string[] => {
  if (!tags || tags.length === 0) return [];

  const TAGS = [
    MISSION_TAGS.TAG_TOPLINEGAP,
    MISSION_TAGS.TAG_PROMOTE_ALGO_SELLING,
    MISSION_TAGS.TAG_PROMOTE_MARKETPLACE,
    MISSION_TAGS.TAG_MARKETPLACEACTIVATION,
    MISSION_TAGS.TAG_PROMOTEREWARDSCHALLENGES,
    MISSION_TAGS.TAG_NO_REWARDS_REDEMPTION,
    MISSION_TAGS.TAG_NO_REWARDS_REDEMPTION_NEW_CASE,
    MISSION_TAGS.TAG_SPONSORED,
    MISSION_TAGS.TAG_SERVICE,
  ];

  let tabs = tags
    .filter((each) => {
      return (
        TAGS.includes(each as MISSION_TAGS) ||
        each.startsWith(MISSION_TAGS.PREFIX_TAG_CAMPAIGNS)
      );
    })
    .map((tag1) => {
      if (tag1 === MISSION_TAGS.TAG_TOPLINEGAP)
        return MISSION_TABS.TAB_TOPLINEGAP;

      if (tag1 === MISSION_TAGS.TAG_PROMOTE_ALGO_SELLING)
        return MISSION_TABS.TAB_QUICK_ORDER;

      if (
        (tag1 === MISSION_TAGS.TAG_MARKETPLACEACTIVATION &&
          tags.some((tag2) => tag2 === MISSION_TAGS.TAG_PROMOTE_MARKETPLACE)) ||
        (tag1 === MISSION_TAGS.TAG_PROMOTE_MARKETPLACE &&
          tags.some((tag2) => tag2 === MISSION_TAGS.TAG_MARKETPLACEACTIVATION))
      ) {
        return MISSION_TABS.TAB_MARKETPLACEACTIVATION;
      }

      if (tag1 === MISSION_TAGS.TAG_PROMOTE_MARKETPLACE)
        return MISSION_TABS.TAB_MARKETPLACE;

      if (tag1 === MISSION_TAGS.TAG_MARKETPLACEACTIVATION)
        return MISSION_TABS.TAB_MARKETPLACEACTIVATION;

      if (tag1 === MISSION_TAGS.TAG_SPONSORED) {
        return isFeatureToggleTabsEnabled.sponsored
          ? MISSION_TABS.TAB_SPONSORED
          : "";
      }

      if (tag1 === MISSION_TAGS.TAG_SERVICE) {
        return isFeatureToggleTabsEnabled.service
          ? MISSION_TABS.TAB_SERVICE
          : "";
      }

      if (tag1 === MISSION_TAGS.TAG_PROMOTEREWARDSCHALLENGES) {
        return MISSION_TABS.TAB_REWARDS_CHALLENGES;
      }

      if (!isFeatureToggleTabsEnabled.wipRewardsRedemption) {
        if (
          tag1 === MISSION_TAGS.TAG_NO_REWARDS_REDEMPTION ||
          tag1 === MISSION_TAGS.TAG_NO_REWARDS_REDEMPTION_NEW_CASE
        ) {
          return MISSION_TABS.TAB_REWARDS_REDEMPTIONS;
        }
      }

      if (tag1.startsWith(MISSION_TAGS.PREFIX_TAG_CAMPAIGNS)) {
        return MISSION_TABS.TAB_CAMPAIGNS;
      }
      return tag1;
    });

  // Remove the duplicates "campaigns"
  tabs = [...new Set(tabs)];

  // Remove the empty positions when the feature toggle is false like line 338
  tabs = tabs.filter((tab) => tab);

  if (
    tabs.some((tab) => tab === MISSION_TAGS.TAG_NO_REWARDS_REDEMPTION) ||
    tabs.some((tab) => tab === MISSION_TAGS.TAG_NO_REWARDS_REDEMPTION_NEW_CASE)
  ) {
    tabs = tabs.filter(
      (tab) =>
        tab !== MISSION_TAGS.TAG_NO_REWARDS_REDEMPTION &&
        tab !== MISSION_TAGS.TAG_NO_REWARDS_REDEMPTION_NEW_CASE,
    );
  }

  if (tabs.some((tab) => tab === MISSION_TABS.TAB_REWARDS_AND_REDEMPTION)) {
    tabs = tabs.filter(
      (tab) =>
        tab !== MISSION_TABS.TAB_REWARDS_CHALLENGES &&
        tab !== MISSION_TABS.TAB_REWARDS_REDEMPTIONS,
    );
  }

  return tabs;
};

export function isTokenExpired(exp: number | null): boolean {
  return !!exp && Date.now() > exp * 1000;
}

export function isRefreshExpired(exp: number | null): boolean {
  return !!exp && Date.now() > (exp - 3300) * 1000;
}

export function isNoHitClassification(classification: string): boolean {
  return (
    classification?.indexOf(CLASSIFICATION_VALUES.NO_ANSWER_DO_NOT_CALL_AGAIN) >
      -1 ||
    classification?.indexOf(CLASSIFICATION_VALUES.RESPONSIBLE_NOT_AVAILABLE) >
      -1 ||
    classification?.indexOf(CLASSIFICATION_VALUES.CALL_LATER_TODAY) > -1 ||
    classification?.indexOf(CLASSIFICATION_VALUES.NO_ANSWER_TRY_AGAIN) > -1 ||
    classification?.indexOf(CLASSIFICATION_VALUES.INCORRECT_PHONE_NUMBER) >
      -1 ||
    classification?.indexOf(CLASSIFICATION_VALUES.POC_CLOSED_DUPLICATE) > -1
  );
}

export function isStrike(classification: string): boolean {
  return classification === CLASSIFICATION_VALUES.SUCCESS_ON_CONTACT;
}

export function getNotesCharactersLength(
  notes: string,
  isNoteChanged: boolean,
  lastNote?: string,
): number {
  if (notes) {
    return notes.length;
  }

  if (lastNote && !isNoteChanged) {
    return lastNote.length;
  }

  return 0;
}

function getDatePlusMillis(stringDate: string, millis: number) {
  const date = new Date(stringDate);
  date.setMilliseconds(millis);
  return date;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function howIsAgent(accountId: string, vendorId: string) {
  return {
    projection: "NAME, OWNER",
    accountId: vendorId ? null : accountId,
    vendorAccountId: vendorId ? accountId : null,
    vendorId,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function howIsAgentCustomerAccount(
  customerAccountId: string,
  vendorId: string,
) {
  return {
    projection: "NAME, OWNER",
    customerAccountId,
    vendorId,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getDatePlus23h59m59s999ms(stringDate: string) {
  const MILLIS_23H59M59S999MS = 86399999;
  return getDatePlusMillis(stringDate, MILLIS_23H59M59S999MS);
}

export function getVendorNameWithFT(vendorId: string, FT: boolean): string {
  const vendorName =
    getEnvironment() !== "PROD"
      ? COMPANY_TOGGLE_UAT[vendorId]
      : COMPANY_TOGGLE_PROD[vendorId];
  const vendorNameFixed = vendorName?.toUpperCase().replace("_", "");
  const vendorNameOrNull = vendorNameFixed || "";
  return FT ? vendorNameOrNull : "";
}

export const returnVendorId = (vendorId: string): string => {
  return getVendorName(vendorId) && vendorId ? vendorId : "";
};
export const returnVendorIdWithFT = (vendorId: string, FT: boolean): string => {
  const vendorIdOrNull = getVendorName(vendorId) && vendorId ? vendorId : "";
  return FT ? vendorIdOrNull : "";
};

export const hasMarketplace = (
  sku: string,
  marketplaceList: Array<MarketplaceItem>,
): boolean => {
  return (
    marketplaceList?.some((each) => {
      const skuMarketplace = sku.replace(/^0+(?!$)/, "");
      const skuMicroservice = each.sku.replace(/^0+(?!$)/, "");
      return skuMicroservice === skuMarketplace;
    }) ?? false
  );
};

export function segmentKey(segmentKeyConfig: string): string {
  const portalLocalStorage = localStorage.getItem("portal-config");

  if (portalLocalStorage) {
    const portalConfig = JSON.parse(portalLocalStorage);
    return portalConfig.TLP_SEGMENT_KEY;
  }

  return segmentKeyConfig;
}

export function returnValueArray(
  values: Array<string> | null | undefined,
  position: number,
): string | null {
  if (values && values.length > 0) {
    return values[position] || null;
  }

  return null;
}

const removeB2OInitialTag = (tag: string) => {
  return tag?.startsWith(B2O_PREFIX) ? tag.replace("b2o-", "") : tag;
};

export const filterMissionsTag = (
  allMissionsTags: Array<string>,
  validB2OTags: Array<string>,
): Array<string> => {
  if (allMissionsTags && allMissionsTags.length > 0) {
    const tagsB2OToRemove: Array<string> = [];
    allMissionsTags.forEach((tag) => {
      if (
        validB2OTags &&
        !validB2OTags.includes(removeB2OInitialTag(tag)) &&
        tag.startsWith(B2O_PREFIX)
      ) {
        tagsB2OToRemove.push(tag);
      }
    });
    const filteredList = allMissionsTags.filter(
      (tag) => !tagsB2OToRemove.includes(tag),
    );
    return filteredList;
  }
  return allMissionsTags;
};

export const groupPaymentInfoArrayBy = (
  array: Array<PaymentMethodInfoItem>,
  key: string,
): Record<string, PaymentMethodInfoItem[]> => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue,
    );
    return result;
  }, {});
};

export const conditionalClass = (
  condition: boolean,
  class1: string,
  class2 = "",
): string => (condition ? class1 : class2);

export const formatTranslationWithPhoneNumber = (
  originalTranslation: string,
  newText: string,
): string => {
  return originalTranslation.replace("{PHONE_NUMBER}", newText);
};

export const isEmpty = (value?: string | number): boolean => {
  if (value === null || value === undefined || value === "") {
    return true;
  }
  return false;
};

export const getTextWidth = (text: string, font?: string): number | null => {
  const canvas = document?.createElement("canvas");
  const context = canvas?.getContext("2d");

  if (context) {
    context.font = font ?? getComputedStyle(document.body)?.font;
    return context?.measureText(text)?.width ?? null;
  }
  return null;
};

export const getValueWithThousandsSeparator = (
  thousandSeparator: string,
  value?: number,
): string => {
  if (!value && value !== 0) {
    return "";
  }

  const formattedValue = value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

  return formattedValue;
};

export const getMessageType = (type: string): string => {
  let messageType;
  switch (type) {
    case "I":
      messageType = "INFO";
      break;
    case "INFO":
      messageType = "INFO";
      break;
    case "S":
      messageType = "SUCCESS";
      break;
    case "W":
      messageType = "WARNING";
      break;
    case "E":
      messageType = "ERROR";
      break;
    case "ERROR":
      messageType = "ERROR";
      break;
    case "ERROR_BLOKED":
      messageType = "ERROR";
      break;
    default:
      messageType = "WARNING";
      break;
  }
  return messageType;
};

export const getPortalConfig = (): string | undefined => {
  const portalLocalStorage = localStorage.getItem(LOCAL_STORAGE_PORTAL_CONFIG);
  if (!portalLocalStorage) return undefined;
  return JSON.parse(portalLocalStorage);
};

export function handleCallEvent(
  key: string | undefined,
  eventType: string | undefined,
): {
  phone?: string;
  callId?: string;
  events: Array<{ type: string | undefined; date: Date }>;
} {
  const regexIsCallId = /^.*?[a-zA-Z]{2}$/;
  const currentTime = new Date();
  const newCallLog = key?.match(regexIsCallId)
    ? {
        callId: key,
        events: [
          {
            type: eventType,
            date: currentTime,
          },
        ],
      }
    : {
        phone: key,
        events: [
          {
            type: eventType,
            date: currentTime,
          },
        ],
      };

  return newCallLog;
}

export const getPackageTypeByVendorItemId = (
  vendorItemId: string,
): PACKAGE_TYPE | null => {
  const packageTypes = Object.values(PACKAGE_TYPE);
  const splitResult = vendorItemId.split("-");
  const packageType = splitResult[splitResult.length - 1] as PACKAGE_TYPE;

  if (packageTypes.includes(packageType)) {
    return packageType;
  }

  return null;
};

export const getEuropeClusterOrUrlParam = (url: string): string => {
  const europeCountries = localStorage.getItem("europeCountries");

  if (europeCountries?.includes(getCountryByToken())) {
    return process.env.EUROPE_HOST + removeUrlPrefix(url);
  }

  return url;
};

const removeUrlPrefix = (url: string) => {
  return url.replaceAll("/api/v1", "").replaceAll("/api/eu", "");
};

const validPromotionComboTypes: Set<PROMOTIONS_TYPES> = new Set([
  PROMOTIONS_TYPES.COMBO_DISCOUNT,
  PROMOTIONS_TYPES.COMBO_DISCOUNT_V2,
  PROMOTIONS_TYPES.COMBO_FREE_GOOD_V2,
]);

export const isPromotionComboTypeValid = (
  promotionComboType: PROMOTIONS_TYPES | undefined,
): boolean => {
  if (!promotionComboType) {
    return false;
  }

  return validPromotionComboTypes.has(promotionComboType);
};

export const getAccountIdFromAgentCallResponse = (response: AgentCall) =>
  response.clientId ?? response.vendorAccountId ?? "";

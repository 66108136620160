/* eslint-disable global-require */
import React, { useLayoutEffect, useRef, useState } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import Tooltip from "@material-ui/core/Tooltip";
import { InfoOutlined } from "@material-ui/icons";
import useTooltipRules from "./hooks/useToolTipRules/useToolTipRules";
import FormatStockExhibition from "../../helpers/FormatStockExhibition";
import GetDisplayPackageType from "../../helpers/GetDisplayPackageType";
import ControlledAddition from "../controlledAddition/ControlledAddition";
import Data from "../data/Data";
import {
  ProductCardV3Styles,
  ProductDetails,
  ProductCardContainer,
  RecommendedCardActions,
  RecommendedCardInformationContainer,
  RecommendedCardPriceContainer,
  InfoStock,
  InfoOutStock,
  PackagingInfo,
  ProductPriceContainer,
} from "./ProductCardV3Styles";
import TooltipInfos from "../productCardTooltipInfo/TooltipInfo";
import DiscountsRangeInfo from "../discountsRangeInfo/DiscountsRangeInfo";
import {
  isFeatureEnabledV2,
  GROW_LOCAL_TAGS_COMPONENT,
  GROW_ITEM_TITLE_TOOLTIP,
  GROW_ADMIN_LOCAL_MISSIONS_STEP2,
  GROW_LOCAL_COMBO_REGULAR_DISCOUNT,
  GROW_LOCAL_REGULAR_DISCOUNT,
  GROW_LOCAL_PRICE_PER_UNIT,
  GROW_LOCAL_STEPPED_DISCOUNT,
  TLP_SPONSORED_PRODUCTS_ORDER_PAGE_LINK,
} from "../../config/featureToggles";
import GlobalStore from "../../stores/global/GlobalStore";
import useProductCard from "../ProductCardV2/hooks/useProductCard/useProductCard";
import { ProductCardInfo } from "../../domains/ProductCardInfo";
import ShowMoreCardInfosModal from "../showMoreCardInfosModal/ShowMoreCardInfosModal";
import TagCarousel from "../../routes/call/components/localMissionsTag/components/tagCarousel/TagCarousel";
import useLocalMissionsTag from "../../routes/call/components/localMissionsTag/hooks/useLocalMissionsTag";
import useProductCardV3 from "./hooks/useProductCardV3/useProductCardV3";
import LoyaltyBusinessStore from "../../stores/loyaltyBusiness/LoyaltyBusinessStore";
import TagCarouselSkeleton from "../../routes/call/components/localMissionsTag/components/tagCarousel/TagCarouselSkeleton";
import ItemPriceNormalDiscount from "../priceComponents/ItemPriceNormalDiscount";
import useProductCardContainer from "../../routes/call/components/sponsoredCard/hooks/useProductCardContainer";
import pocInformationStore from "../../stores/callList/pocInformation/PocInformationStore";
import ItemOriginalPriceWithNormalDiscount from "../priceComponents/ItemOriginalPriceWithNormalDiscount";

const offeringImagePlaceholder =
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  require("../../assets/images/ProductPlaceholder.png") as string;

const ProductCardV3: React.FunctionComponent<ProductCardInfo> = ({
  product,
  isMarketplaceProduct = false,
  mission,
  promotion,
  showPromoTag = false,
  showPromoPrice = false,
  minValue,
}) => {
  const classes = ProductCardV3Styles();
  const { t } = useTranslation();
  const {
    user: { zone },
  } = useStore(GlobalStore);
  const { loading } = useStore(LoyaltyBusinessStore);
  const [src, setSrc] = useState(product.image || "");
  const { pocInformation } = useStore(pocInformationStore);

  const { isCombo, isPromotion } = product;

  const { addAction, showAddButton, showStock, maxValue } =
    useProductCardContainer({
      products: mission?.products || [],
      pocInformation,
      isMarketplaceProduct,
      item: product,
    });

  const {
    discount,
    handleChangeQuantity,
    handleAddAction,
    renderSuggestedQuantityWithEffectiveness,
    itemQuantity,
    setItemQuantity,
  } = useProductCard({
    product,
    promotion,
    minValue,
    addAction: addAction(product, mission),
    mission,
  });

  const { handleTitle, isTooltipDisabled } = useTooltipRules(product);

  const { priceAndName, discountRangesValues } = useProductCardV3({
    product,
    itemQuantity,
  });

  const [isShowMoreModalOpen, setIsShowMoreModalOpen] = useState(false);
  const sku = product.sku || product.vendorComboId || product.id;

  const isItemTitleTooltipEnabled = isFeatureEnabledV2(
    GROW_ITEM_TITLE_TOOLTIP,
    zone,
  );

  const isSliderLocalTagsEnabled = isFeatureEnabledV2(
    GROW_LOCAL_TAGS_COMPONENT,
    zone,
  );

  const isLOCALMISSIONSSTEP2Enabled = isFeatureEnabledV2(
    GROW_ADMIN_LOCAL_MISSIONS_STEP2,
    zone,
  );

  const isLocalComboRegularDiscountEnabled = isFeatureEnabledV2(
    GROW_LOCAL_COMBO_REGULAR_DISCOUNT,
    zone,
  );

  const isLocalProductRegularDiscountEnabled = isFeatureEnabledV2(
    GROW_LOCAL_REGULAR_DISCOUNT,
    zone,
  );

  const isLocalPricePerUnitEnabled = isFeatureEnabledV2(
    GROW_LOCAL_PRICE_PER_UNIT,
    zone,
  );

  const isLocalSteppedDiscountEnabled = isFeatureEnabledV2(
    GROW_LOCAL_STEPPED_DISCOUNT,
    zone,
  );

  const isAddedTextOrderLinkEnabled = isFeatureEnabledV2(
    TLP_SPONSORED_PRODUCTS_ORDER_PAGE_LINK,
    zone,
  );

  const productTags = useLocalMissionsTag(product);

  const shouldRenderPricePerUnit =
    isLocalPricePerUnitEnabled &&
    priceAndName.pricePerUnit &&
    priceAndName.pricePerUnit > -1 &&
    !isCombo;

  const renderNewItemPriceNormalDiscount = () => {
    return (
      isPromotion &&
      ((isLocalComboRegularDiscountEnabled && product.isCombo) ||
        (isLocalProductRegularDiscountEnabled &&
          product.discountType === "DISCOUNT"))
    );
  };

  const showPromo = isPromotion && showPromoTag;

  const shouldRenderOriginalPrice = () => {
    return (product.originalPrice ||
      (discount && isPromotion && showPromoPrice)) as boolean;
  };

  /* istanbul ignore next */
  const comboFormattedTitle = (text: string): string => {
    const maxLengthForTwoLines = 50;

    if (text.length <= maxLengthForTwoLines) {
      return text;
    }

    const trimmedText = text.slice(0, maxLengthForTwoLines);
    const lastSpaceIndex = trimmedText.lastIndexOf(" ");

    const truncatedText =
      lastSpaceIndex > 0 ? trimmedText.slice(0, lastSpaceIndex) : trimmedText;

    return `${truncatedText}...`;
  };

  const textElementRef = useRef<HTMLInputElement | null>();
  const [isOverflowedText, setIsOverflowedText] = useState(true);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (textElementRef.current)
        setIsOverflowedText(
          textElementRef.current.scrollWidth >
            textElementRef.current.clientWidth ||
            textElementRef.current.scrollHeight >
              textElementRef.current.clientHeight ||
            isCombo,
        );
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [textElementRef, isCombo]);

  return (
    <div className={classes.cardWrapper}>
      {isSliderLocalTagsEnabled &&
        (loading ? (
          <TagCarouselSkeleton data-testid="tag-carousel-skeleton" />
        ) : (
          <TagCarousel tags={productTags} />
        ))}
      {product.comboDetails?.length && (
        <ShowMoreCardInfosModal
          isOpen={isShowMoreModalOpen}
          onClose={() => setIsShowMoreModalOpen(false)}
          price={product.price}
          endDate={product.endDate}
          totalDailyBalance={product.limit?.totalDailyBalance}
          totalMonthlyBalance={product.limit?.totalMonthlyBalance}
          comboDetails={product.comboDetails}
          data-testId="show-more-card-info-modal"
        />
      )}
      <ProductCardContainer
        data-testid={`${sku}-container`}
        className={productTags.length === 0 ? classes.productTop : ""}
      >
        <img
          src={src || offeringImagePlaceholder}
          className={
            showStock(isCombo) && product.stock === 0
              ? classes.recommendedCardItemImageOvershadowed
              : classes.recommendedCardItemImage
          }
          alt={`${sku}-product`}
          data-testid={`${sku}-product-image`}
          /* istanbul ignore next */
          onError={() => setSrc(offeringImagePlaceholder)}
        />
        <div>
          <RecommendedCardInformationContainer>
            <div>
              {isItemTitleTooltipEnabled ? (
                <Tooltip
                  ref={textElementRef}
                  arrow
                  placement="top"
                  classes={{
                    tooltip: classes.tooltipTitleContainer,
                    arrow: classes.tooltipArrow,
                  }}
                  disableHoverListener={!isOverflowedText}
                  title={
                    <TooltipInfos
                      comboDetails={product.comboDetails}
                      itemName={product.name}
                      fullContainerDescription={
                        product.fullContainerDescription
                      }
                      fullPackageDescription={product.fullPackageDescription}
                    />
                  }
                  data-testid="item-card-title-tooltip"
                >
                  {product.comboDetails?.length ? (
                    <div data-testid="combo-details-container">
                      <span
                        className={classes.comboTitleText}
                        data-testid="combo-details-text"
                      >
                        {comboFormattedTitle(handleTitle())}
                      </span>
                      <span
                        className={classes.recommendedCardShowMoreInfo}
                        onClick={() => setIsShowMoreModalOpen(true)}
                        role="presentation"
                        data-testid="combo-details-show-more-button"
                      >
                        {t("MISSIONS.CARD.showMoreButton")}
                      </span>
                    </div>
                  ) : (
                    /* istanbul ignore next */
                    <span
                      className={classes.recommendedCardInformationTitleTooltip}
                    >
                      {handleTitle()}
                    </span>
                  )}
                </Tooltip>
              ) : (
                <Data
                  value={product.name}
                  className={classes.recommendedCardInformationTitle}
                  testId={`${sku}-description-text`}
                  hasTooltip
                />
              )}

              <Data
                value={sku}
                className={classes.recommendedCardInformationSKU}
                testId={`${sku}-sku-text`}
                hasTooltip
              />

              {product.variant && (
                <PackagingInfo data-testid={`${sku}-packaging-info`}>
                  <Typography
                    variant="h2"
                    className={classes.packagingInfoLabel}
                  >
                    {t("COMMONS_PRODUCTS_INFOS.PACKAGING_LABEL")}:
                  </Typography>
                  <Typography
                    className={classes.packagingInfoText}
                    variant="h2"
                  >
                    {GetDisplayPackageType(
                      product.package?.packageId,
                      product.package?.name,
                    )}
                  </Typography>
                </PackagingInfo>
              )}
            </div>

            <ProductDetails>
              <RecommendedCardPriceContainer>
                <ItemOriginalPriceWithNormalDiscount
                  renderNewItemPriceNormalDiscount={renderNewItemPriceNormalDiscount()}
                  productPrice={product.price ?? product.unitPrice}
                  sku={sku}
                  originalPrice={product.originalPrice}
                  shouldRenderOriginalPrice={shouldRenderOriginalPrice()}
                />

                <ProductPriceContainer>
                  <Typography
                    variant="h2"
                    className={classes.recommendedCardInformationPrice}
                    data-testid={`${sku}-price-container`}
                  >
                    {isLocalSteppedDiscountEnabled &&
                    product.discountType === "STEPPED_DISCOUNT" &&
                    discountRangesValues?.length ? (
                      <div className={classes.steppedDiscountPrice}>
                        <Data
                          type="currency"
                          /* istanbul ignore next */
                          className={
                            classes.recommendedCardInformationPriceWithPromo
                          }
                          value={
                            discountRangesValues[
                              discountRangesValues.length - 1
                            ].price
                          }
                          testId={`${sku}-price-start-value`}
                        />
                        <span className={classes.steppedDiscountPriceSpan}>
                          -
                        </span>
                        <Data
                          type="currency"
                          /* istanbul ignore next */
                          className={
                            classes.recommendedCardInformationPriceWithPromo
                          }
                          value={discountRangesValues[0].price}
                          testId={`${sku}-price-end-value`}
                        />
                      </div>
                    ) : (
                      <ItemPriceNormalDiscount
                        renderNewItemPriceNormalDiscount={renderNewItemPriceNormalDiscount()}
                        productPrice={product.price ?? product.unitPrice}
                        sku={sku}
                        showPromoPrice={showPromoPrice}
                        discount={discount}
                        originalPrice={product.originalPrice}
                        showPromo={showPromo}
                      />
                    )}
                  </Typography>
                  {shouldRenderPricePerUnit && (
                    <Typography
                      variant="subtitle1"
                      className={classes.pricePerUnit}
                      data-testid={`${sku}-price-container-per-unit`}
                    >
                      <Data type="currency" value={priceAndName.pricePerUnit} />
                      {`/${priceAndName.namePerUnit}`}
                    </Typography>
                  )}

                  {isLocalSteppedDiscountEnabled &&
                    product.discountType === "STEPPED_DISCOUNT" &&
                    discountRangesValues?.length && (
                      <Typography
                        data-testid={`${sku}-discount-range`}
                        className={classes.discountRangesValues}
                      >
                        <DiscountsRangeInfo values={discountRangesValues} />
                      </Typography>
                    )}
                </ProductPriceContainer>

                {isLOCALMISSIONSSTEP2Enabled &&
                  renderSuggestedQuantityWithEffectiveness(
                    sku,
                    classes.recommendedCardInformationSuggestedRedesign,
                  )}
              </RecommendedCardPriceContainer>
            </ProductDetails>
          </RecommendedCardInformationContainer>
          {showAddButton(isCombo) && (
            <RecommendedCardActions
              data-testid={`recommended-controlled-addition-container-${sku}`}
            >
              <ControlledAddition
                testId="recommended-controlled-addition"
                addAction={handleAddAction}
                itemQuantityChangeAction={handleChangeQuantity}
                showCartLink={isAddedTextOrderLinkEnabled}
                minValue={minValue}
                maxValue={maxValue(product)}
                variant="secondary"
                itemQuantity={itemQuantity}
                setItemQuantity={setItemQuantity}
              />
            </RecommendedCardActions>
          )}
          <div className={classes.stockContainer}>
            {showStock(isCombo) &&
              product.stock !== null &&
              !!product?.stock &&
              product?.stock > 0 && (
                <InfoStock data-testid={`${sku}-stock-text`}>
                  {FormatStockExhibition(product.stock)}
                  {` ${t("RecommendedProducts.IN_STOCK")}`}
                </InfoStock>
              )}
            {isPromotion &&
              isLocalProductRegularDiscountEnabled &&
              !!product.limit?.daily &&
              !isCombo && (
                <Tooltip
                  data-testid="tooltip-isPomotion"
                  arrow
                  placement="bottom"
                  classes={{
                    tooltip: classes.stockTooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  disableHoverListener={isTooltipDisabled()}
                  title={
                    <TooltipInfos
                      itemName={`${product.limit?.daily} ${t(
                        "MISSIONS.CARD.promotionProductQuantityMessage",
                      )}`}
                    />
                  }
                >
                  <InfoOutlined className={classes.InfoOutlined} />
                </Tooltip>
              )}
          </div>
          {showStock(isCombo) &&
            product.stock != null &&
            product.stock <= 0 && (
              <InfoOutStock data-testid={`${sku}-out-of-stock-text`}>
                {t("RecommendedProducts.OUT_OF_STOCK")}
              </InfoOutStock>
            )}
        </div>
      </ProductCardContainer>
    </div>
  );
};

export default ProductCardV3;

import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    box: {
      position: "relative",
    },
    container: {
      backgroundColor: palette.common.black,
      height: "100%",
      boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.16)",
      width: 254,
      overflow: "hidden",
      borderRadius: 0,
      flexDirection: "column",
      overflowX: "hidden",
      overflowY: "auto",
      paddingBottom: "30px",
    },
    id: {
      fontSize: "12px",
      fontWeight: 500,
      color: palette.common.white,
      marginBottom: "16px",
      paddingLeft: "27px",
    },
    image: {
      borderRadius: "3px",
      marginRight: "16px",
      marginTop: "-10%",
      marginBottom: "10%",
      "& img": {
        objectFit: "contain",
      },
    },
    buttonItem: {
      flex: 1,
    },
    reloadButton: {
      alignSelf: "center",
      padding: "2px",
    },
    content: {
      display: "inline-block",
      color: "white",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
    errorText: {
      color: "#FFFF",
      fontSize: "12px",
      textAlign: "left",
      padding: "8px",
    },
  }),
);

/* eslint-disable global-require */
import React, { useState } from "react";
import { useStore } from "effector-react";
import { Paper, Box } from "@material-ui/core";
import CollapsibleSideNavStyles from "./CollapsibleSideNavStyles";
import PocInformationStore from "../../../../stores/callList/pocInformation/PocInformationStore";
import { useCollapsibleSideNavContext } from "../../../../contexts/CollapsibleSideNavContext";
import { POC_INFO_TAB } from "../../../../config/constants";
import BoxPocInfo from "./components/boxPocInfo/BoxPocInfo";
import CollapseButton from "./components/collapseButton/CollapseButton";
import PocDetails from "./components/pocDetails/PocDetails";

function CollapsibleSideNav(): React.ReactElement {
  const classes = CollapsibleSideNavStyles();
  const { pocInformation } = useStore(PocInformationStore);
  const [tab, setTab] = useState(POC_INFO_TAB);

  const { collapsibleIsOpen, handleCollapsible } =
    useCollapsibleSideNavContext();

  const showTab = collapsibleIsOpen ? "flex" : "none";

  return (
    <Box className={classes.box}>
      <Paper
        className={classes.container}
        style={{ display: showTab }}
        data-testid="collapsible-container"
      >
        <BoxPocInfo
          pocName={pocInformation?.name}
          vendorName={pocInformation.vendorName}
        />
        <div
          className={classes.container}
          data-testid="poc-sales-navigator-container"
        >
          <PocDetails tab={tab} setTab={setTab} />
        </div>
      </Paper>
      <CollapseButton
        tab={tab}
        collapsibleIsOpen={collapsibleIsOpen}
        handleCollapsible={handleCollapsible}
      />
    </Box>
  );
}
export default CollapsibleSideNav;

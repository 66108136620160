import {
  NewOfferingsItemV2,
  Promotion,
} from "@services/newOfferings/domains/NewOfferingsResponseItem";
import { CampaignProduct } from "@stores/campaigns/CampaignsState";
import { PromotionsInfoV3 } from "@converters/promotions/promotionsV3/domains/PromotionsV3ResponseToPromotionsInfoConverterDomain";
import { Promotions } from "@domains/promotions/Promotions";
import MarketplaceStore from "@stores/marketplace/MarketplaceStore";
import CampaignsStore from "@stores/campaigns/CampaignsStore";
import { hasMarketplace } from "@config/utils/functions";

import PromotionsStore from "@stores/promotions/PromotionsStore";
import { Product, PromotionPrices, Range } from "@domains/products/Product";
import { FocusSku } from "@services/campaigns/domains/CampaignsResponse";
import { MarketplaceItem } from "@domains/Marketplace";

const MAX_RECOMMENDED_PRODUTS = 20;

export const RecommendedMarketplaceProductsV2Converter = (
  data: NewOfferingsItemV2[],
): Product[] => {
  const { marketplaceList } = MarketplaceStore.getState();
  const { focus } = CampaignsStore.getState();
  const { promotions } = PromotionsStore.getState();

  const recommendationsWithMarketplace: Product[] = data.map(
    convertResponseToProduct,
  );

  const focusWithMarketplace: Product[] = getFocusWithMarketplace(
    focus,
    promotions,
    marketplaceList,
    recommendationsWithMarketplace?.length,
  );

  const allRecomendedMarketplace: Product[] = [
    ...recommendationsWithMarketplace,
    ...focusWithMarketplace,
  ];

  return removeDuplicatedKeepingOrder(allRecomendedMarketplace).splice(
    0,
    MAX_RECOMMENDED_PRODUTS,
  );
};

function removeDuplicatedKeepingOrder(products: Product[]): Product[] {
  const uniqueArray: Product[] = [];
  const uniquePlatformIds = new Set();
  products.forEach((product) => {
    if (!uniquePlatformIds.has(product.platformId)) {
      uniquePlatformIds.add(product.platformId);
      uniqueArray.push(product);
    }
  });
  return uniqueArray;
}

function getFocusWithMarketplace(
  focus: FocusSku[],
  promotions: PromotionsInfoV3[],
  marketplaceList: MarketplaceItem[],
  firstPriority: number,
): Product[] {
  return focus
    .flatMap((each) => {
      return each.campaignProducts.filter((product) =>
        hasMarketplace(product.sku, marketplaceList),
      );
    })
    .map((each: CampaignProduct, indice: number) => {
      const filteredPromotionBySku = promotions.find(
        (promotion) => promotion.platformId === each.itemPlatformId,
      );
      const priority = firstPriority + indice + 1;
      return convertFocusToProduct(each, priority, filteredPromotionBySku);
    });
}

function convertFocusToProduct(
  focusSku: CampaignProduct,
  priority: number,
  promotion?: PromotionsInfoV3,
): Product {
  return {
    id: focusSku.id,
    vendorItemId: focusSku.vendorItemId,
    pricePerUoM: focusSku.pricePerUoM,
    platformId: focusSku.itemPlatformId,
    palletQuantity: focusSku.palletQuantity,
    inventoryCount: focusSku.inventoryCount,
    availability: {
      count: focusSku.availabity?.count,
      source: focusSku.availabity?.source,
    },
    sku: focusSku.sku,
    name: focusSku.productName,
    stock: focusSku.stock,
    unitPrice: focusSku.unitPrice,
    image: focusSku.image,
    priority,
    isFocus: true,
    isRecommended: false,
    isPromotion: !!promotion,

    promotion: convertFocusPromotion(promotion),
    availabilityCount: focusSku.availabity?.count,
    isSponsored: false,
    isCombo: false,
    isMarketplace: true,
    price: focusSku.unitPrice,
    ranges: <Range[]>(<unknown>promotion?.ranges), // Intentional wrong convertion to keep legacy behavior. We need a refac to fix this
  };
}

function convertFocusPromotion(
  promotion?: PromotionsInfoV3,
): Promotions | undefined {
  if (!promotion) {
    return undefined;
  }
  return <Promotions>(<unknown>promotion); // Intentional wrong convertion to keep legacy behavior. We need a refac to fix this
}

function convertResponseToProduct(response: NewOfferingsItemV2): Product {
  return {
    id: response.id,
    platformId: response.platformId,
    sku: response.vendorItemId,
    vendorItemId: response.vendorItemId,

    name: response.name,
    productName: response.name,
    image: response.imageURL,
    priority: response.priority,
    isFocus: false,
    isRecommended: true,
    isSponsored: false,
    isPromotion: !!response.promotion,
    isOnSale: response.isPromotion,
    isMarketplace: response.isMarketplace,
    vendorComboId: response.vendorComboId,
    isCombo: response.isCombo,
    price: response.price.originalPrice, // Original price intentional to keep behavior of discount shown
    originalPrice: response.price.originalPrice,
    unitPrice: response.price.pricePerUnit,
    pricePerUoM: response.price.pricePerUoM,
    inventoryCount: response.inventory?.inventoryCount,
    stock: response.inventory?.inventoryCount,
    variant: response.variant,
    suggestedQuantity: response.suggestedQuantity,
    fullContainerDescription: response.container?.fullContainerDescription,
    fullPackageDescription: response.package?.fullPackageDescription,
    container: response.container,
    package: response.package,

    discountType: response.promotion?.promotionType,
    promotion: convertResponsePromotion(response, response.promotion),
    ranges: convertResponsePromotionRanges(response.promotion),
    prices: convertResponsePromotionPrices(response, response.promotion),
  };
}

function convertResponsePromotion(
  response: NewOfferingsItemV2,
  promotion: Promotion,
): Promotions | undefined {
  if (!promotion) {
    return undefined;
  }
  return {
    id: promotion.id,
    platformId: promotion.platformId,
    sku: response.vendorItemId,
    itemId: response.id,
    itemName: response.name,
    originalPrice: response.price?.originalPrice,
    title: promotion.title,
    description: promotion.description,
    type: promotion.promotionType,
    prices: convertResponsePromotionPrices(response, promotion),
    freeGoods: [],
    marketplace: response.isMarketplace,
    inventoryCount: response.inventory?.inventoryCount,
    fullPackageDescription: response.package?.fullPackageDescription,
    fullContainerDescription: response.container?.fullContainerDescription,
  };
}

function convertResponsePromotionRanges(promotion: Promotion): Range[] {
  if (!promotion?.ranges) {
    return [];
  }
  return promotion.ranges.map((range) => {
    return {
      discountRate: range.discountRate,
      stepStart: range.initialQuantity,
      stepEnd: range.finalQuantity,
    };
  });
}

function convertResponsePromotionPrices(
  response: NewOfferingsItemV2,
  promotion: Promotion,
): PromotionPrices[] {
  if (!promotion?.ranges) {
    return [];
  }
  return promotion.ranges.map((range) => {
    return {
      id: `${range.initialQuantity}-${range.finalQuantity}`,
      stepStart: range.initialQuantity,
      stepEnd: range.finalQuantity,
      discountedPrice: range.price,
      discountedRate: range.discountRate,
      discountAmount: response.price.originalPrice - range.price,
    };
  });
}

// COUNTRY CONFIGS

import { Gap } from "@stores/agentCall/AgentCallState";

export const CURRENCY: { [key: string]: CurrencyCountry } = {
  DO: { currency: "DOP", prefix: "RD$" },
  BR: { currency: "BRL", prefix: "R$" },
  US: { currency: "USD", prefix: "$" },
  EC: { currency: "USD", prefix: "$" },
  ES: { currency: "EUR", prefix: "€" },
  CO: { currency: "COP", prefix: "$" },
  ZA: { currency: "ZAR", prefix: "R" },
  PE: { currency: "PEN", prefix: "S/" },
  MX: { currency: "MXN", prefix: "$" },
  PA: { currency: "PAB", prefix: "B/." },
  HN: { currency: "HNL", prefix: "L" },
  SV: { currency: "USD", prefix: "$" },
  UY: { currency: "UYU", prefix: "$" },
  PY: { currency: "PYG", prefix: "Gs." },
  AR: { currency: "ARS", prefix: "$" },
  UG: { currency: "UGX", prefix: "UGX" },
  PH: { currency: "PHP", prefix: "₱" },
  ID: { currency: "IDR", prefix: "Rp" },
  CL: { currency: "CPL", prefix: "$" },
  TZ: { currency: "TZS", prefix: "TZS" },
  BO: { currency: "BOB", prefix: "BOB" },
  TH: { currency: "THB", prefix: "฿" },
  GH: { currency: "GHS", prefix: "GH₵" },
};

export const DATE_FORMATTER = {
  BR: "DD/MM/YYYY",
  DO: "DD/MM/YYYY",
  EC: "DD/MM/YYYY",
  ES: "DD/MM/YYYY",
  CO: "DD/MM/YYYY",
  ZA: "YYYY/MM/DD",
  PE: "DD/MM/YYYY",
  MX: "DD/MM/YYYY",
  US: "YYYY/MM/DD",
  PA: "DD/MM/YYYY",
  HN: "DD/MM/YYYY",
  SV: "DD/MM/YYYY",
  UY: "DD/MM/YYYY",
  PY: "DD/MM/YYYY",
  AR: "DD/MM/YYYY",
  UG: "DD/MM/YYYY",
  TZ: "DD/MM/YYYY",
  PH: "DD/MM/YYYY",
  ID: "DD/MM/YYYY",
  CL: "DD/MM/YYYY",
  TH: "DD/MM/YYYY",
  GH: "DD/MM/YYYY",
};

// CONSTANTS

export const BASE_NAME = "/tlp";
export const LINK_HOME = `${BASE_NAME}/home`;
export const LINK_CALL = `${BASE_NAME}/call`;
export const NEW_MFE = `${BASE_NAME}/call/service`;
export const LINK_SERVER_ERROR = `${BASE_NAME}/server-error`;
export const LINK_POC_LIST = `${BASE_NAME}/poc-list`;
export const LINK_POC_CREATE = `${BASE_NAME}/poc-create`;
export const TAB_GAP_ROUTE = "/tlp/call/gap";
export const TAB_CUSTOMER_ROUTE = "/tlp/call/customer";
export const TAB_B2B_TRAINER_ROUTE = "/tlp/call/b2btrainer";
export const TAB_ORDERS_ROUTE = "/tlp/call/orders";
export const VALID_ROUTES = [
  LINK_CALL,
  TAB_CUSTOMER_ROUTE,
  TAB_GAP_ROUTE,
  TAB_ORDERS_ROUTE,
];

export const LOCAL_STORAGE_PORTAL_CONFIG = "portal-config";
export const LOCAL_STORAGE_REFRESH_TOKEN = "refresh_token";
export const WRAPPER_PROJECT_NAME_INSTANCE = "admin-portal-tlp-beta-mfe";

export const INPUTTED_PHONE = "INPUTTED_PHONE";
export const ERP_PHONE = "ERP_PHONE";
export const BEES_PHONE = "BEES_PHONE";

export const TAB_PARAMETER = "tab";
export const TAB_GAP = "gap";
export const TAB_CUSTOMER = "customer";
export const TAB_B2B_TRAINER = "b2b-trainer";
export const TAB_ORDERS = "orders";
export const CALL_ID_IDX = "00004405542020-08-17T17:38:18";

export const ANALYTICS_ROUTE_NAMES = new Map<string, string>([
  [LINK_HOME, "Agent KPIs"],
  [LINK_CALL, "Call - GAP and Status"],
  [LINK_SERVER_ERROR, "Server error page"],
  [TAB_GAP, "Call - GAP and Status"],
  [TAB_B2B_TRAINER, "Call - B2B Trainer"],
  [TAB_ORDERS, "Call - Orders"],
  [TAB_CUSTOMER, "Customer"],
  [`${LINK_CALL}?${TAB_PARAMETER}=${TAB_GAP}`, "Call - GAP and Status"],
  [`${LINK_CALL}?${TAB_PARAMETER}=${TAB_CUSTOMER}`, "Customer"],
  [`${LINK_CALL}?${TAB_PARAMETER}=${TAB_B2B_TRAINER}`, "Call - B2B Trainer"],
  [`${LINK_CALL}?${TAB_PARAMETER}=${TAB_ORDERS}`, "Call - Orders"],
  [TAB_GAP_ROUTE, "Call - GAP and Status"],
  [TAB_B2B_TRAINER_ROUTE, "Call - B2B Trainer"],
  [TAB_ORDERS_ROUTE, "Call - Orders"],
  [TAB_CUSTOMER_ROUTE, "Customer"],
  [`${LINK_CALL}?${TAB_PARAMETER}=${TAB_GAP_ROUTE}`, "Call - GAP and Status"],
  [`${LINK_CALL}?${TAB_PARAMETER}=${TAB_CUSTOMER_ROUTE}`, "Customer"],
  [
    `${LINK_CALL}?${TAB_PARAMETER}=${TAB_B2B_TRAINER_ROUTE}`,
    "Call - B2B Trainer",
  ],
  [`${LINK_CALL}?${TAB_PARAMETER}=${TAB_ORDERS_ROUTE}`, "Call - Orders"],
]);
export const DATE_FORMAT_STRING = "yyyy-MM-DDTHH:mm:ss.SSS[Z]";
export const ENTER_KEY_CODE = 13;
export const TYPE_PHONE = "PHONE";
export const TYPE_EMAIL = "EMAIL";

export const CHANNEL = "CX_TLP";

export const ANALYTICS_REGISTRATION_ERROR_TYPE = "call registration error";

export const EMULATOR_TIMEOUT = 120;

export const WEB_SOCKECT_URI =
  process.env.NODE_ENV === "development"
    ? "ws://localhost:9001"
    : "ws://localhost:8088";

export const UNDEFINED_GAP: Gap = {
  topline: {
    total: undefined,
    achieved: undefined,
    target: undefined,
  },
  volume: {
    total: undefined,
    achieved: undefined,
    target: undefined,
  },
};

export const UPDATE_LOCAL_STORAGE_LOGOUT: string[] = [
  "authTimestamp",
  "ajs_user_traits",
  "ajs_group_properties",
  "ajs_user_id",
  "ajs_anonymous_id",
  "ajs_group_id",
  "portal-config",
  "authHeader",
  "refresh_token",
  "refresh_token_expires_in",
  "azure_auth",
  "canary",
];

export const NEW_OFFERINGS_RECOMMENDATIONS = {
  USE_CASE: {
    CROSS_SELL_UP_SELL: "CROSS_SELL_UP_SELL",
  },
  USE_CASE_TYPE: {
    ACCOUNT: "ACCOUNT",
  },
};

export const AZURE_B2C_URI_PREFIX_PROD =
  "https://b2biamgbusprod.b2clogin.com/b2biamgbusprod.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_BEES_SIGNIN_ONCUSTOMER&redirect_uri=";

export const AZURE_B2C_REDIRECT_URI_PROD_B2C =
  "https://beesgrow.bees-platform.com/";

export const AZURE_B2C_REDIRECT_URI_PROD_EUROPE_B2C =
  "https://beesgrow-eu.bees-platform.com/#/login";

export const AZURE_B2C_REDIRECT_URI_PROD =
  "https://admin-portal.bees-platform.com/#/login";

export const SQ_FORECAST = "#SQForecast";

export const SEGMENTATION_COUNTRIES: string[] = ["BR", "CO", "ZA", "PE", "MX"];

export const B2O_PREFIX = "#b2o-";
export const FOCUS_SKU_TYPE = "focus_sku";
export const FOCUS_SKU_EQUALIZER = "Focus SKU";

export const CALL_ID = "callId";
export const RESPONSEDATE_IN_MILLIS = "responseDateInMillis";
export const CURRENTDATE_IN_MILLIS = "currentDateInMillis";

export const NO_HIT: string[] = [
  "No Answer",
  "No Answer - try again",
  "Incorrect Phone Number",
  "Poc Closed Duplicated",
];

export const MAX_VALUE_INPUT = 9999;
export const MIN_VALUE_INPUT = 1;

export const POC_INFO_TAB = "POC_INFO_TAB";
export const SALES_STRUCTURE_TAB = "SALES_STRUCTURE_TAB";

export const VENDOR_ID = "";

export const BRF_PREFIX = "BRF";

// ENUMS

export enum EndCallActions {
  NEXT_POC = "next-poc",
  GO_HOME = "go-home",
  GO_HOME_TAKE_BREAK = "go-home-take-break",
}

export enum OFFERINGS_SECTION {
  NEW_OFFERINGS = "NEW_OFFERINGS",
  PROMOTIONS = "PROMOTIONS",
}

export enum MISSION_TAGS {
  TAG_TOPLINEGAP = "#TopLineGAP",
  TAG_PROMOTE_ALGO_SELLING = "#PromoteAlgoSelling",
  TAG_PROMOTE_MARKETPLACE = "#PromoteMarketPlace",
  TAG_MARKETPLACEACTIVATION = "#marketplaceactivation",
  TAG_PROMOTEREWARDSCHALLENGES = "#promoterewardschallenges",
  TAG_NO_REWARDS_REDEMPTION = "#norewardsredemption",
  TAG_NO_REWARDS_REDEMPTION_NEW_CASE = "#NoRewardsRedemption",
  PREFIX_TAG_CAMPAIGNS = "#b2o-",
  TAG_SPONSORED = "#sponsored",
  TAG_SERVICE = "#digital_services",
  TAG_LOCAL_MISSIONS = "#LocalMissions",
}

export enum MISSION_TABS {
  TAB_TOPLINEGAP = "toplineGap",
  TAB_QUICK_ORDER = "quickOrder",
  TAB_MARKETPLACE = "marketplace",
  TAB_MARKETPLACEACTIVATION = "marketplaceactivation",
  TAB_REWARDS_CHALLENGES = "rewards",
  TAB_REWARDS_REDEMPTIONS = "rewardsRedemption",
  TAB_REWARDS_AND_REDEMPTION = "rewardsAndRedemption",
  TAB_CAMPAIGNS = "campaigns",
  TAB_SPONSORED = "sponsored",
  TAB_SERVICE = "service",
  TAB_LOCAL_MISSIONS = "localMissions",
}

export enum PROMOTIONS_ABA_TYPES {
  COMBO = "combo",
  PROMOTION = "promotion",
}

export enum PROMOTIONS_TYPES {
  COMBO_FREE_GOOD = "FG",
  COMBO_DISCOUNT = "D",
  COMBO_DISCOUNT_V2 = "FIXED_COMBO_DISCOUNT",
  COMBO_FREE_GOOD_V2 = "FIXED_COMBO_FREE_GOOD",
  COMBO_INTERACTIVE = "INTERACTIVE_COMBO",
  COMBO_INTERACTIVE_QUANTITY = "QUANTITY",
  PROM_FREE_GOOD = "FREE_GOOD",
  PROM_STEPPED_FREE_GOOD = "STEPPED_FREE_GOOD",
  PROM_STEPPED_DISCOUNT = "STEPPED_DISCOUNT",
  PROM_DISCOUNT = "DISCOUNT",
}

export enum PACKAGE_TYPE {
  EA = "EA",
  DS = "DS",
  CS = "CS",
}

export enum POC_INFO_ABA_TYPES {
  ADDITIONAL_INFORMATION = "additional_information",
}

export enum WS_EVENTS {
  INCOMING_CALL = "IncomingCall",
  DISCONNECT_CALL = "DisconnectCall",
  MAKE_CALL = "MakeCall",
  GET_STATUS = "GetStatus",
  MAKE_CALL_ACK = "MakeCall_ACK",
  MAKE_CALL_FAIL = "MakeCall_Fail",
  KEEP_ALIVE = "KeepAlive",
  PING = "ping",
  PONG = "pong",
}

export enum OrderNavigationTabName {
  SUGGESTED_ORDER = "suggested_order",
  LAST_ORDER = "last_order",
  CAMPAIGNS = "campaigns",
}

export enum STOCKOUT_OPTIONS {
  DONT_DO_ANYTHING = "don't do anything",
  DISPLAY_INDICATOR = "display stockout visual indicator",
  DISPLAY_WARN = "display the warn and the value",
}

export enum EFFECTIVENESS_ENUM {
  criteriaReached = "EFFECTIVENESS_CRITERIA_HAVE_BEEN_REACHED",
  firstContactMade = "FIRST_CONTACT_IS_MADE",
}

export enum PocCallOrigin {
  SEARCH_BY_POC = "SEARCH_BY_POC",
  GET_NEXT_CLIENT = "GET_NEXT_CLIENT",
  AUTO_DIALER = "AUTO_DIALER",
}

export enum LocalGlobalFilterEnum {
  LOCAL = "LOCAL",
  GLOBAL = "GLOBAL",
  BOTH = "BOTH",
}

export enum MISSION_TYPES {
  GLOBAL = "GLOBAL",
  LOCAL = "LOCAL",
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",

  SIMPLE = "SIMPLE",
  COVERAGE = "COVERAGE",
  FLEXIBLE = "FLEXIBLE",
}

export enum FILTERED_GLOBAL_CALL_LIST {
  SPONSORED = "#sponsored",
  LOCAL_MISSIONS = "#LocalMissions",
  FLEXIBLE_MISSIONS = "#flexible_missions",
}

export enum MISSION_CATEGORIES {
  FINTECH = "FINTECH",
  MARKETPLACE = "MARKETPLACE",
  REWARDS = "REWARDS",
  SERVICES = "SERVICES",
}

export enum TARGET_KPI_TYPES {
  AHT = "AHT",
  CALLS = "CALLS",
  UPLIFT = "UPLIFT",
  DISTINCT_PRODUCT = "DISTINCT_PRODUCT",
  DPU = "DPU",
  HR = "HR",
}

export enum COMBO_ITEM_TYPE {
  AMOUNT = "AMOUNT",
  QUANTITY = "QUANTITY",
}

// INTERFACES

interface CurrencyCountry {
  currency: string;
  prefix: string;
}

// FUNCTIONS

export const isCallRoute = (pathName: string): boolean =>
  VALID_ROUTES.some((route) => route === pathName);

function safeDecodeURIComponent(input) {
  try {
    return decodeURIComponent(input);
  } catch (e) {
    return input;
  }
}

// Bees Trainer

export const WV_PROD_DO = "webversion-prod-do";
export const WV_PROD_BR = "webversion-prod-br";
export const WV_PROD_EC = "webversion-prod-ec";
export const WV_PROD_ES = "webversion-prod-es";
export const WV_PROD_CO = "webversion-prod-co";
export const WV_PROD_PE = "webversion-prod-pe";
export const WV_PROD_MX = "webversion-prod-mx";
export const WV_PROD_PA = "webversion-prod-pa";
export const WV_PROD_HN = "webversion-prod-hn";
export const WV_PROD_SV = "webversion-prod-sv";
export const WV_PROD_UY = "webversion-prod-uy";
export const WV_PROD_PY = "webversion-prod-py";
export const WV_PROD_AR = "webversion-prod-ar";
export const WV_PROD_ZA = "webversion-prod-za";
export const WV_PROD_UG = "webversion-prod-ug";
export const WV_PROD_ID = "webversion-prod-id";
export const WV_PROD_PH = "webversion-prod-ph";
export const WV_PROD_CL = "webversion-prod-cl";
export const WV_PROD_TZ = "webversion-prod-tz";
export const WV_PROD_BO = "webversion-prod-bo";
export const WV_PROD_TH = "webversion-prod-th";
export const WV_UAT_DO = "webversion-uat-do";
export const WV_UAT_BR = "webversion-uat-br";
export const WV_UAT_EC = "webversion-uat-ec";
export const WV_UAT_ES = "webversion-uat-es";
export const WV_UAT_CO = "webversion-uat-co";
export const WV_UAT_PE = "webversion-uat-pe";
export const WV_UAT_MX = "webversion-uat-mx";
export const WV_UAT_PA = "webversion-uat-pa";
export const WV_UAT_HN = "webversion-uat-hn";
export const WV_UAT_SV = "webversion-uat-sv";
export const WV_UAT_UY = "webversion-uat-uy";
export const WV_UAT_PY = "webversion-uat-py";
export const WV_UAT_AR = "webversion-uat-ar";
export const WV_UAT_ZA = "webversion-uat-za";
export const WV_UAT_UG = "webversion-uat-ug";
export const WV_UAT_PH = "webversion-uat-ph";
export const WV_UAT_ID = "webversion-uat-id";
export const WV_UAT_CL = "webversion-uat-cl";
export const WV_UAT_TZ = "webversion-uat-tz";
export const WV_UAT_BO = "webversion-uat-bo";
export const WV_UAT_TH = "webversion-uat-th";

export const WB_VERSION = new Map([
  ["DO_PROD", "webversion-prod-do"],
  ["BR_PROD", "webversion-prod-br"],
  ["EC_PROD", "webversion-prod-ec"],
  ["ES_PROD", "webversion-prod-es"],
  ["CO_PROD", "webversion-prod-co"],
  ["PE_PROD", "webversion-prod-pe"],
  ["MX_PROD", "webversion-prod-mx"],
  ["PA_PROD", "webversion-prod-pa"],
  ["HN_PROD", "webversion-prod-hn"],
  ["SV_PROD", "webversion-prod-sv"],
  ["UY_PROD", "webversion-prod-uy"],
  ["PY_PROD", "webversion-prod-py"],
  ["AR_PROD", "webversion-prod-ar"],
  ["ZA_PROD", "webversion-prod-za"],
  ["ID_PROD", "webversion-prod-id"],
  ["UG_PROD", "webversion-prod-ug"],
  ["PH_PROD", "webversion-prod-ph"],
  ["CL_PROD", "webversion-prod-cl"],
  ["TZ_PROD", "webversion-prod-tz"],
  ["BO_PROD", "webversion-prod-bo"],
  ["TH_PROD", "webversion-prod-th"],
  ["DO_UAT", "webversion-uat-do"],
  ["BR_UAT", "webversion-uat-br"],
  ["EC_UAT", "webversion-uat-ec"],
  ["ES_UAT", "webversion-uat-es"],
  ["CO_UAT", "webversion-uat-co"],
  ["PE_UAT", "webversion-uat-pe"],
  ["MX_UAT", "webversion-uat-mx"],
  ["PA_UAT", "webversion-uat-pa"],
  ["HN_UAT", "webversion-uat-hn"],
  ["SV_UAT", "webversion-uat-sv"],
  ["UY_UAT", "webversion-uat-uy"],
  ["PY_UAT", "webversion-uat-py"],
  ["AR_UAT", "webversion-uat-ar"],
  ["ZA_UAT", "webversion-uat-za"],
  ["UG_UAT", "webversion-uat-ug"],
  ["PH_UAT", "webversion-uat-ph"],
  ["ID_UAT", "webversion-uat-id"],
  ["CL_UAT", "webversion-uat-cl"],
  ["TZ_UAT", "webversion-uat-tz"],
  ["BO_UAT", "webversion-uat-bo"],
  ["TH_UAT", "webversion-uat-th"],
]);

export const WEB_VERSION_LINKS = new Map<string, string>([
  [WV_PROD_DO, "https://do.uat.bees-platform.dev/"],
  [WV_PROD_BR, "https://br.uat.bees-platform.dev/"],
  [WV_PROD_EC, "https://ec.uat.bees-platform.dev/"],
  [WV_PROD_ES, "https://es.uat.bees-platform.dev/"],
  [WV_PROD_CO, "https://co.uat.bees-platform.dev/"],
  [WV_PROD_PE, "https://pe.uat.bees-platform.dev/"],
  [WV_PROD_MX, "https://mx.uat.bees-platform.dev/"],
  [WV_PROD_PA, "https://pa.uat.bees-platform.dev/"],
  [WV_PROD_HN, "https://hn.uat.bees-platform.dev/"],
  [WV_PROD_SV, "https://sv.uat.bees-platform.dev/"],
  [WV_PROD_UY, "https://uy.uat.bees-platform.dev/"],
  [WV_PROD_PY, "https://py.uat.bees-platform.dev/"],
  [WV_PROD_AR, "https://ar.uat.bees-platform.dev/"],
  [WV_PROD_ZA, "https://za.uat.bees-platform.dev/"],
  [WV_PROD_UG, "https://ug.uat.bees-platform.dev/"],
  [WV_PROD_PH, "https://ph.uat.bees-platform.dev/"],
  [WV_PROD_CL, "https://cl.uat.bees-platform.dev/"],
  [WV_PROD_ID, "https://id.uat.bees-platform.dev/"],
  [WV_PROD_TZ, "https://tz.uat.bees-platform.dev/"],
  [WV_PROD_BO, "https://bo.uat.bees-platform.dev/"],
  [WV_PROD_TH, "https://th.uat.bees-platform.dev/"],
  [WV_UAT_DO, "https://do.uat.bees-platform.dev/"],
  [WV_UAT_BR, "https://br.uat.bees-platform.dev/"],
  [WV_UAT_EC, "https://ec.uat.bees-platform.dev/"],
  [WV_UAT_ES, "https://es.uat.bees-platform.dev/"],
  [WV_UAT_CO, "https://co.uat.bees-platform.dev/"],
  [WV_UAT_PE, "https://pe.uat.bees-platform.dev/"],
  [WV_UAT_MX, "https://mx.uat.bees-platform.dev/"],
  [WV_UAT_PA, "https://pa.uat.bees-platform.dev/"],
  [WV_UAT_HN, "https://hn.uat.bees-platform.dev/"],
  [WV_UAT_SV, "https://sv.uat.bees-platform.dev/"],
  [WV_UAT_UY, "https://uy.uat.bees-platform.dev/"],
  [WV_UAT_PY, "https://py.uat.bees-platform.dev/"],
  [WV_UAT_AR, "https://ar.uat.bees-platform.dev/"],
  [WV_UAT_ZA, "https://za.uat.bees-platform.dev/"],
  [WV_UAT_UG, "https://ug.uat.bees-platform.dev/"],
  [WV_UAT_PH, "https://ph.uat.bees-platform.dev/"],
  [WV_UAT_ID, "https://id.uat.bees-platform.dev/"],
  [WV_UAT_CL, "https://cl.uat.bees-platform.dev/"],
  [WV_UAT_TZ, "https://tz.uat.bees-platform.dev/"],
  [WV_UAT_BO, "https://bo.uat.bees-platform.dev/"],
  [WV_UAT_TH, "https://th.uat.bees-platform.dev/"],
]);

export const LOGIN_COUNTRY_B2B_TRAINNING = new Map<string, string>([
  ["DO", decodeURIComponent("b2b-trainer-dr%40ab-inbev.com")],
  ["BR", decodeURIComponent("b2b.trainer.br%40gmail.com")],
  ["EC", decodeURIComponent("b2b.trainer.ec%40gmail.com")],
  ["ES", decodeURIComponent("b2b.trainer.es%40gmail.com")],
  ["CO", decodeURIComponent("b2b.trainer.co%40gmail.com")],
  ["ZA", decodeURIComponent("b2b.trainer.za%40gmail.com")],
  ["PE", decodeURIComponent("b2b-trainer-pe%40ab-inbev.com")],
  ["MX", decodeURIComponent("b2b.trainer.mx%40gmail.com")],
  ["PA", decodeURIComponent("b2b-trainer-pa%40ab-inbev.com")],
  ["HN", decodeURIComponent("b2b.trainer.hn%40gmail.com")],
  ["SV", decodeURIComponent("b2b-trainer-sv%40ab-inbev.com")],
  ["UY", decodeURIComponent("b2b-trainer-uy%40ab-inbev.com")],
  ["PY", decodeURIComponent("b2b-trainer-py%40ab-inbev.com")],
  ["AR", decodeURIComponent("b2b-trainer-ar%40ab-inbev.com")],
  ["UG", decodeURIComponent("b2b-trainer-ug%40ab-inbev.com")],
  ["TZ", decodeURIComponent("b2b-trainer-tz%40ab-inbev.com")],
  ["PH", decodeURIComponent("b2b.trainer.ph.nestle%40gmail.com")],
  ["ID", decodeURIComponent("bees.grow.uat+nestleid%40gmail.com")],
  ["CL", safeDecodeURIComponent("bees.grow.uat+chile%40gmail.com")],
]);

export const PASSWORD_COUNTRY_B2B_TRAINNING = new Map<string, string>([
  ["DO", "Abi123456"],
  ["BR", decodeURIComponent("Active%40123")],
  ["EC", decodeURIComponent("Active%40123")],
  ["ES", decodeURIComponent("Active%40123")],
  ["CO", decodeURIComponent("Active%40123")],
  ["ZA", decodeURIComponent("Active%40123")],
  ["PE", decodeURIComponent("Welcome%40123")],
  ["MX", decodeURIComponent("Active%40123")],
  ["PA", decodeURIComponent("Welcome%40123")],
  ["HN", decodeURIComponent("Active%40123")],
  ["SV", decodeURIComponent("Elsalvador2022!%40")],
  ["UY", decodeURIComponent("Uruguay2022!%40")],
  ["PY", decodeURIComponent("Paraguay2022!%40")],
  ["AR", decodeURIComponent("Argentina2022!%40")],
  ["UG", decodeURIComponent("Welcome%401234")],
  ["TZ", decodeURIComponent("Welcome%401234")],
  ["PH", decodeURIComponent("Active%40123")],
  ["ID", decodeURIComponent("Active%40123")],
  ["CL", decodeURIComponent("Active%40123")],
]);

// VendorIds

export const mercaSIDVendorId = [
  "0ea1b79d-b836-4475-bac7-2001168de568",
  "c153705d-0929-4c95-bcc7-23338e90d3c9",
  "bb1d68ad-fafa-4e91-b64d-713c92e99db7",
  "2d741ecd-9e84-4107-aaed-a6f189528a07",
];

export const gloriaPEVendorId = [
  "8dfdc938-79b5-40f7-b21e-031405fa75b9",
  "8445c121-3697-4f24-9128-9baf81ab0292",
  "1de61e80-f08d-434e-a0b8-dc266e0a68da",
  "c069c91e-ab82-4fdd-83ab-057bb1b11993",
];

export const gloriaCOVendorId = [
  "4cbe004b-26da-4557-8525-2488e02285c8",
  "bb967dbf-b8b6-4105-a5d4-c2816da62b69",
  "973c80dd-f30b-425b-9074-f1c60ede46a2",
];

export const nestleBrasilVendorId = [
  "219b9c83-59e2-4921-bfb2-91733c5b3bb8",
  "bf7e222b-c638-4982-b03e-622f51223ff7",
  "d8e9fbc3-0678-4778-beb7-74165906d3fa",
  "95ea158a-6a14-4f56-ba78-484c3a467e84",
];

export const nestleEquadorVendorId = [
  "e03865d9-f3c2-4040-8e21-e3fe7a8ddd4f",
  "2abf054e-4c48-4634-b390-b982efd056ea",
  "70b33632-6bce-4eeb-95a2-ba0be90bf908",
  "dec2a3ed-926c-4441-8abb-aa1f6b433b47",
];

export const nestleIndonesiaVendorId = [
  "e0e276fc-e617-4578-be27-606f09739505",
  "0f493bc6-f9e7-4a1b-949f-2c9e75cf3062",
  "ebf5c02e-9510-4880-9fd2-a8031525eae9",
];

export const grupoPaoDeAcucarBrasilVendorId = [
  "07b1f778-8206-4582-9237-2a8244014d54",
  "c2d7f53e-f6d4-40a8-afe6-61c58a25ec9d",
  "dd6e82ad-c47d-4688-8a4c-76137c90f7d2",
  "fca0073f-93cf-4170-a088-9b311fc9f1e0",
];

// No DEV environment
export const nestleActiservePhilippinesVendorId = [
  "50482c93-38af-4c3b-8f8a-d5924465bc01",
  "07f6fa92-5976-412c-b3ac-57e1d613ab7b",
  "4bb70dc8-2361-47fc-a4d2-6413761037a4",
];

// No DEV environment
export const nestleCavitePhilippinesVendorId = [
  "5d41d232-4278-4cee-adfd-cb0ec3704d12",
  "8f7e3a26-f91f-4fea-ac94-b6beb0b15e3a",
  "daa1e312-91ca-4bd9-b28a-70fb138be688",
];

export const pepsicoBrasilVendorId = [
  "1ed33cd8-ef04-4188-9f7a-9bff6b1d0c64",
  "cf9721b3-17e6-42f4-94b6-7837a8d89946",
  "7cea584a-9007-4092-914f-3357a93ed52e",
  "cc8e0da1-6d9c-44db-8056-1702b9fcfe00",
];

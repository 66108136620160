import TimeZone from "../domains/TimeZone";

export const TIMEZONE: { [key: string]: TimeZone } = {
  BR: { locale: "pt-BR", timeZone: "America/Sao_Paulo" },
  CO: { locale: "es-CO", timeZone: "America/Bogota" },
  EC: { locale: "es-EC", timeZone: "America/Guayaquil" },
  ES: { locale: "es-ES", timeZone: "Europe/Madrid" },
  DO: { locale: "es-DO", timeZone: "America/Dominica" },
  ZA: { locale: "en-ZA", timeZone: "Africa/Johannesburg" },
  PE: { locale: "es-PE", timeZone: "America/Lima" },
  MX: { locale: "es-MX", timeZone: "America/Mexico_City" },
  PA: { locale: "es-PA", timeZone: "America/Panama" },
  HN: { locale: "es-HN", timeZone: "America/Tegucigalpa" },
  SV: { locale: "es-SV", timeZone: "America/El_Salvador" },
  UY: { locale: "es-UY", timeZone: "America/Montevideo" },
  PY: { locale: "es-PY", timeZone: "America/Asuncion" },
  AR: { locale: "es-AR", timeZone: "America/Buenos_Aires" },
  UG: { locale: "en-UG", timeZone: "Africa/Kampala" },
  PH: { locale: "en-PH", timeZone: "Asia/Manila" },
  ID: { locale: "en-ID", timeZone: "Asia/Jakarta" },
  CL: { locale: "es-CL", timeZone: "America/Santiago" },
  TZ: { locale: "en-TZ", timeZone: "Africa/Dar_es_Salaam" },
  BO: { locale: "es-BO", timeZone: "America/La_Paz" },
  TH: { locale: "en-TH", timeZone: "Asia/Bangkok" },
  GH: { locale: "en-GH", timeZone: "Africa/Accra" },
};

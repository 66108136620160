import React, { memo, useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Data from "@components/data/Data";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { InteractiveCombo } from "@domains/promotions/InteractiveCombo";
import Button from "@components/button/Button";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { COMBO_ITEM_TYPE } from "@config/constants";

import comboStyle from "./ComboInteractiveCardStyles";

interface Props {
  allExpanded: boolean;
  interactiveCombo: InteractiveCombo;
  setDrawerOpen: (value: boolean) => void;
}

export const ComboInteractiveCard: React.FC<Props> = ({
  allExpanded,
  interactiveCombo,
  setDrawerOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const classes = comboStyle();

  const { id, name, description, remainingDays, sharedItems } =
    interactiveCombo;

  useEffect(() => {
    setIsOpen(allExpanded);
  }, [allExpanded]);

  return (
    <Accordion
      className={classes.Accordion}
      expanded={isOpen}
      data-testid="combo-interactive-card"
    >
      <AccordionSummary
        classes={{
          root: classes.accordionSummaryRoot,
          content: classes.accordionSummaryContent,
        }}
      >
        <div className={classes.accordionText} data-testid="titleSummary">
          {name}
        </div>

        <ButtonBase
          onClick={() => setIsOpen(!isOpen)}
          data-testid="combo-card-expand-button"
          className={classes.accordionExpandButton}
        >
          {isOpen ? (
            <RemoveIcon
              className={classes.accordionIcon}
              data-testid={`combo-card-remove-icon-${id}`}
            />
          ) : (
            <AddIcon
              className={classes.accordionIcon}
              data-testid={`combo-card-add-icon-${id}`}
            />
          )}

          <div
            className={classes.accordionExpandButtonLabel}
            data-testid="button-text"
          >
            {isOpen
              ? t("ComboInteractive.HIDE_DESCRIPTION")
              : t("ComboInteractive.SHOW_DESCRIPTION")}
          </div>
        </ButtonBase>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetails}
        data-testid="combo-card-details"
      >
        <p
          className={classes.descriptionAvailable}
          data-testid="item-description"
        >
          {description ?? "No Data Available."}
        </p>

        {sharedItems?.map(({ items, minimumQuantity, sharedModel }, key) => {
          const count = key + 1;

          return (
            <>
              <p
                className={classes.descriptionSelection}
                data-testid={`item-section-text-${count}`}
              >
                {sharedModel === COMBO_ITEM_TYPE.QUANTITY ? (
                  t("ComboInteractive.SECTION_QUANTITY", {
                    count,
                    minimumQuantity,
                  })
                ) : (
                  <>
                    {t("ComboInteractive.SECTION_PRICE", { count })}{" "}
                    <Data type="currency" value={minimumQuantity} />{" "}
                    {t("ComboInteractive.SECTION_PRICE_2", { count })}
                  </>
                )}
              </p>

              {items?.map(({ id, title, originalValue, discountValue }) => {
                return (
                  <>
                    <p className={classes.itemsName} data-testid="item-name">
                      {title}
                    </p>

                    <div className={classes.priceContainer} key={id}>
                      <Data
                        value={originalValue}
                        type="currency"
                        className={classes.originalPrice}
                        key={`combo-price_${id}`}
                        testId={`combo-price_${id}`}
                      />
                      /
                      <Data
                        value={discountValue}
                        type="currency"
                        className={classes.price}
                        key={`combo-discount_${id}`}
                        testId={`combo-discount_${id}`}
                      />
                    </div>
                  </>
                );
              })}
            </>
          );
        })}

        <Button
          type="button"
          className={classes.button}
          onClick={() => setDrawerOpen(true)}
        >
          {t("ComboInteractive.SELECT_PRODUCTS")}
        </Button>
        <p
          className={classes.descriptionCombo}
          data-testid="item-combo-available"
        >
          {t("ComboInteractive.COMBO_AVAILABLE", { remainingDays })}
        </p>
      </AccordionDetails>
    </Accordion>
  );
};
memo(ComboInteractiveCard);

import React from "react";
import Typography from "@material-ui/core/Typography";
import { Combo as ComboType } from "../../../../../domains/promotions/Combo";
import { Promotions as PromotionsType } from "../../../../../domains/promotions/Promotions";
import Data from "../../../../../components/data/Data";
import cardStyles from "./CardStyles";
import { PROMOTIONS_ABA_TYPES } from "../../../../../config/constants";

interface Props {
  data: ComboType | PromotionsType;
  type: PROMOTIONS_ABA_TYPES;
}

const DiscountSection: React.FC<Props> = ({ data, type }) => {
  const classes = cardStyles();

  const renderDiscount = () => {
    if (type === PROMOTIONS_ABA_TYPES.PROMOTION) {
      const promotions = data as PromotionsType;

      return (
        <>
          <Typography className={classes.skuDescription}>
            <Data
              value={`${promotions.sku} - ${promotions.title}`}
              type="text"
              testId={`discount-sku-description-${data.id}`}
            />
          </Typography>
          {promotions.prices?.map((price) => (
            <div className={classes.priceContainer} key={price.id}>
              <Data
                value={promotions.originalPrice}
                type="currency"
                className={classes.originalPrice}
                key={`promotions-price_${price.id}`}
                testId={`promotions-price_${price.id}`}
              />
              /
              <Data
                value={price.discountedPrice}
                type="currency"
                className={classes.price}
                key={`promotions-discount_${price.id}`}
                testId={`promotions-discount_${price.id}`}
              />
            </div>
          ))}
        </>
      );
    }

    const combos = data as ComboType;

    return (
      <div className={classes.priceContainer}>
        <Data
          value={combos.originalPrice}
          type="currency"
          className={classes.originalPrice}
        />
        /
        <Data value={combos.price} type="currency" className={classes.price} />
      </div>
    );
  };

  return renderDiscount();
};

export default DiscountSection;

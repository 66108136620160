export interface LanguageOption {
  value: string;
  label: string;
}

export const DEFAULT_LANGUAGE = "en-US";

export const languageMap: { [key: string]: LanguageOption } = {
  DO: { value: "es-419", label: "ES" },
  US: { value: "en-US", label: "EN" },
  BR: { value: "pt-BR", label: "PT" },
};

export const mapLanguageByZone = new Map<Array<string>, LanguageOption>([
  [
    ["DO", "EC", "ES", "CO", "PE", "MX", "PA", "HN", "SV", "UY", "PY", "CL"],
    { value: "es-419", label: "ES" },
  ],
  [["US", "ZA", "UG", "PH", "TZ", "BO", "TH", "GH"], { value: "en-US", label: "EN" }],
  [["ID"], { value: "id-ID", label: "ID" }],
  [["BR"], { value: "pt-BR", label: "PT" }],
  [["AR"], { value: "es-AR", label: "AR" }],
]);

export const getLanguageByZone = (zone: string): LanguageOption => {
  for (const [key, value] of mapLanguageByZone) {
    if (key.includes(zone)) {
      return value;
    }
  }
  return languageMap.DO;
};

export const languageMapV2: { [key: string]: LanguageOption } = {
  ES: { value: "es-419", label: "ES" },
  EN: { value: "en-US", label: "EN" },
  PT: { value: "pt-BR", label: "PT" },
};

export const languageHubMap: LanguageOption[] = [
  { value: "es-419", label: "ES" },
  { value: "en-US", label: "EN" },
  { value: "pt-BR", label: "PT" },
];

export const getLanguageHubByZone = (
  preferredLanguage: string,
): LanguageOption => {
  for (const language of languageHubMap) {
    if (language.value.includes(preferredLanguage)) {
      return language;
    }
  }
  return languageMapV2.EN;
};
